import React from 'react'

import { RibbonTriggerContainer } from 'RibbonTrigger'

import { AboutYou } from 'routes/Checkout/Components/AboutYou/AboutYou'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'

type CreateAccountProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
}

export const CreateAccount = ({ onStepChange }: CreateAccountProps) => (
  <>
    <AboutYou onStepChange={onStepChange} />
    <RibbonTriggerContainer name="checkout-account" />
  </>
)
