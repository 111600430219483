import React from 'react'

import { Box, Display, FlexDirection, AlignItems } from '@gousto-internal/citrus-react'

import { BenefitItem } from './BenefitItem'
import { BenefitType, BENEFITS } from './data'

type BenefitsProps = {
  pickFilter: BenefitType[]
}

export const Benefits = ({ pickFilter }: BenefitsProps) => {
  const benefits = BENEFITS.filter(({ type }) => pickFilter.includes(type))

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      width="100%"
      alignItems={[AlignItems.Center, AlignItems.FlexStart]}
    >
      {benefits.map(({ type, iconName, text, strongText, ariaLabel }) => (
        <BenefitItem
          key={type}
          iconName={iconName}
          text={text}
          strongText={strongText}
          ariaLabel={ariaLabel}
        />
      ))}
    </Box>
  )
}
