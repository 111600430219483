import React from 'react'

import { BorderStyle, Box, Color } from '@gousto-internal/citrus-react'

import { BoxDetails } from 'routes/Checkout/Components/BoxDetails/BoxDetails'
import { Summary } from 'routes/Checkout/Components/Summary/Summary'

type OrderSummaryAndBoxDetailsProps = {
  isCreatingPreviewOrder: boolean
}

/**
 * Container for "Order Summary" and "Box details" containers showed during checkout.
 */
export const OrderSummaryAndBoxDetails = ({
  isCreatingPreviewOrder,
}: OrderSummaryAndBoxDetailsProps) => (
  <Box borderStyle={BorderStyle.Solid} borderWidth={0.5} borderColor={Color.NeutralGrey_200}>
    <Summary isLoading={isCreatingPreviewOrder} />
    <BoxDetails />
  </Box>
)
