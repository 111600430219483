import React from 'react'

import { Box } from '@gousto-internal/citrus-react'

import { ExpandableBoxSummary } from 'routes/Checkout/Components/ExpandableBoxSummary/ExpandableBoxSummary'
import { OrderSummaryAndBoxDetails } from 'routes/Checkout/Components/OrderSummaryAndBoxDetails'
import { usePricing } from 'routes/Menu/domains/pricing'

type BoxSummaryDrawerProps = {
  isCreatingPreviewOrder: boolean
}

/**
 * Drawer for "Total Price" and "Box Summary" container that is visible only on small-screen devices.
 * Note: for other than small-screen devices drawer is not expected to be shown as price and summary would be shown
 * on every step anyway.
 */
export const BoxSummaryDrawer = ({ isCreatingPreviewOrder }: BoxSummaryDrawerProps) => {
  const { pricing } = usePricing()

  return (
    <Box data-testing="checkoutExpandableBoxSummary" data-testid="ExpandableBoxSummary">
      <ExpandableBoxSummary
        totalToPay={pricing?.total || ''}
        totalWithoutDiscount={pricing?.recipeTotal || ''}
        promoCodeValid={pricing?.promoCodeValid}
      >
        <OrderSummaryAndBoxDetails isCreatingPreviewOrder={isCreatingPreviewOrder} />
      </ExpandableBoxSummary>
    </Box>
  )
}
