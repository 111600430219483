import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux'

import { AppModalContainer } from 'components/AppModal'
import menuConfig from 'config/menu'
import { CommunicationPanel } from 'routes/Menu/MenuRecipesPage/CommunicationPanel'

import Loading from '../Loading'
import { CollectionsNavContainer } from '../components/CollectionsNav'
import { JustForYouTutorial } from '../components/JustForYouTutorial'
import { MenuPreferencesTutorial } from '../components/MenuPreferences/MenuPreferencesTutorial'
import { SubHeaderContainer } from '../components/SubHeader'
import { UpdateSignupDietaryPreferences } from '../components/UpdateSignupDietaryPreferences'
import { CapacityInfo } from './CapacityInfo'
import { CollectionHeaderWrapper } from './CollectionHeader'
import { RecipeGrid } from './RecipeGrid'

import css from './MenuRecipesPage.css'

export class MenuRecipesPage extends PureComponent {
  async componentDidMount() {
    const {
      orderId,
      params,
      storeOrderId,
      basketOrderLoaded,
      query,
      portionSizeSelectedTracking,
      numPortions,
      fetchMenuData,
      addRecipe,
    } = this.props
    // if server rendered
    if (orderId && orderId === storeOrderId) {
      basketOrderLoaded(orderId)
    }
    const forceDataLoad = (storeOrderId && storeOrderId !== orderId) || query.reload
    // TODO: Add back logic to check what needs to be reloaded
    if (forceDataLoad) {
      await fetchMenuData({ query, params }, forceDataLoad, undefined, { addRecipe })
    }
    if (orderId) {
      portionSizeSelectedTracking(numPortions, orderId)
    }
  }

  componentDidUpdate(prevProps) {
    const { orderId, query, params, fetchMenuData, addRecipe } = this.props

    // /menu-> /menu/:orderId
    const editingOrder = (prevProps.orderId || orderId) && prevProps.orderId !== orderId
    if (editingOrder) {
      fetchMenuData({ query, params }, true, undefined, { addRecipe })
    }
  }

  getModals = () => {
    const { isLoading, showMenuPreferencesTutorial } = this.props

    return (
      <>
        {!isLoading && showMenuPreferencesTutorial && <MenuPreferencesTutorial />}
        {!isLoading && <JustForYouTutorial />}
        <AppModalContainer key="app-modal" />
      </>
    )
  }

  getMenuRecipeListContent = () => {
    const {
      stateRecipeCount,
      showCommunicationPanel,
      menuLoadingErrorMessage,
      query,
      isDoubleDeckerFeatureOn,
      signupDietaryPreferencesEnabled,
      menuCurrentCollectionId,
      menuPreferences,
    } = this.props
    const { communicationPanel } = menuConfig
    const showError = !!menuLoadingErrorMessage && !stateRecipeCount

    return (
      <div>
        {showCommunicationPanel ? (
          <div className={css.communicationPanelContainer}>
            <div className={css.communicationPanel}>
              <CommunicationPanel
                showIcon={communicationPanel.showIcon}
                title={communicationPanel.title}
                body={communicationPanel.body}
              />
            </div>
          </div>
        ) : null}

        {!isDoubleDeckerFeatureOn && <SubHeaderContainer />}

        <CollectionsNavContainer />

        <CollectionHeaderWrapper />

        {stateRecipeCount > 0 && (
          <>
            <RecipeGrid
              query={query}
              doubleDeckerExperimentEnabled={isDoubleDeckerFeatureOn}
              menuCurrentCollectionId={menuCurrentCollectionId}
              menuPreferences={menuPreferences}
              signupDietaryPreferencesEnabled={signupDietaryPreferencesEnabled}
            />
            {signupDietaryPreferencesEnabled && <UpdateSignupDietaryPreferences />}
          </>
        )}

        {showError ? (
          <h2 className={css.menuLoadingErrorMessage}>{menuLoadingErrorMessage}</h2>
        ) : null}

        {this.getModals()}
      </div>
    )
  }

  render() {
    const { showLoading, shouldShowCapacityInfo, userId } = this.props

    if (showLoading) {
      return (
        <div data-testing="menuRecipes">
          <Loading loading />
        </div>
      )
    }

    if (shouldShowCapacityInfo) {
      return (
        <div data-testing="menuRecipes">
          <CapacityInfo userId={userId} />
        </div>
      )
    }

    return (
      <div data-testing="menuRecipes">
        <div className={css.willFade}>{this.getMenuRecipeListContent()}</div>
      </div>
    )
  }
}

MenuRecipesPage.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  stateRecipeCount: PropTypes.number.isRequired,
  basketOrderLoaded: PropTypes.func.isRequired,
  portionSizeSelectedTracking: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isLoading: PropTypes.bool,
  storeOrderId: PropTypes.string,
  numPortions: PropTypes.number,
  query: PropTypes.shape({
    reload: PropTypes.bool,
    collection: PropTypes.string,
  }),
  params: PropTypes.shape({}),
  showCommunicationPanel: PropTypes.bool,
  userId: PropTypes.string,
  shouldShowCapacityInfo: PropTypes.bool,
  menuLoadingErrorMessage: PropTypes.string,
  fetchMenuData: PropTypes.func.isRequired,
  addRecipe: PropTypes.func.isRequired,
  isDoubleDeckerFeatureOn: PropTypes.bool,
  signupDietaryPreferencesEnabled: PropTypes.bool.isRequired,
  menuCurrentCollectionId: PropTypes.string,
  menuPreferences: PropTypes.shape({
    display_preferences: PropTypes.bool,
  }),
  showMenuPreferencesTutorial: PropTypes.bool,
}

MenuRecipesPage.defaultProps = {
  orderId: null,
  isLoading: false,
  storeOrderId: '',
  numPortions: 2,
  query: {},
  params: {},
  showCommunicationPanel: false,
  userId: '',
  shouldShowCapacityInfo: false,
  menuLoadingErrorMessage: '',
  isDoubleDeckerFeatureOn: false,
  menuCurrentCollectionId: '',
  menuPreferences: null,
  showMenuPreferencesTutorial: false,
}
MenuRecipesPage.contextType = ReactReduxContext
