/* eslint-disable indent */
import Immutable from 'immutable'
import moment from 'moment'

import { isServer } from '@library/environment'

import { actionTypes } from 'actions/actionTypes'

import { mapMenuVariants, updateSelectedVariants } from '../utils/menu'

export const menuInitialState = Immutable.Map({
  menuVariants: Immutable.fromJS({}),
  selectedRecipeVariants: {},
  hasCalculatedTimeToUsable: false,
  hasVisitedNonMenuPage: false,
  menuLoadingErrorMessage: '',
})

export const menuReducers = {
  menu: (state = menuInitialState, action) => {
    switch (action.type) {
      case actionTypes.MENU_SERVICE_DATA_RECEIVED: {
        const menuVariants = mapMenuVariants(action.response.data)

        return state
          .set('menuLoadingErrorMessage', '')
          .set('menuVariants', Immutable.fromJS(menuVariants))
      }

      case actionTypes.MENU_RECIPE_VARIANT_INIT: {
        const { selectedRecipeVariants } = action.payload

        return state.set('selectedRecipeVariants', selectedRecipeVariants)
      }

      case actionTypes.MENU_RECIPE_VARIANT_SELECTED: {
        const originalVariants = state.get('selectedRecipeVariants')
        const newVariants = updateSelectedVariants(originalVariants, action.payload)

        return state.set('selectedRecipeVariants', newVariants)
      }

      case actionTypes.MENU_SELL_THE_PROPOSITION_TILE_CLOSED: {
        return state.set('hasClosedPropositionTile', true)
      }

      case actionTypes.MENU_SET_CALCULATED_TIME_TO_USABLE: {
        return state.set('hasCalculatedTimeToUsable', true)
      }

      case '@@router/LOCATION_CHANGE': {
        if (isServer()) {
          return state
        }

        const {
          payload: { pathname },
        } = action

        if (pathname !== '/menu') {
          return state.set('hasVisitedNonMenuPage', true)
        }

        return state
      }

      case actionTypes.MENU_LOADING_ERROR: {
        return state.set('menuLoadingErrorMessage', action.message)
      }

      default:
        return state
    }
  },
  menuRecipeDetails: (state = Immutable.Map({}), action) => {
    switch (action.type) {
      case actionTypes.MENU_RECIPE_DETAIL_VISIBILITY_CHANGE: {
        if (action.categoryId) {
          return state.set('recipeUuid', action.recipeUuid)
        }

        return state.set('recipeUuid', action.recipeUuid)
      }

      default:
        return state
    }
  },

  menuCutoffUntil: (state = '', action) => {
    switch (action.type) {
      case actionTypes.MENU_CUTOFF_UNTIL_RECEIVE: {
        return action.cutoffUntil || state
      }

      default:
        return state
    }
  },

  menuRecipes: (state = Immutable.List(), action) => {
    switch (action.type) {
      case actionTypes.RECIPES_RECEIVE: {
        const newState = Immutable.List(action.recipes.map((recipe) => recipe.id))

        return newState
      }

      default:
        return state
    }
  },

  menuRecipesUpdatedAt: (state = null, action) => {
    switch (action.type) {
      case actionTypes.RECIPES_RECEIVE: {
        return moment.now()
      }

      default:
        return state
    }
  },

  menuBoxPrices: (state = Immutable.Map({}), action) => {
    switch (action.type) {
      case actionTypes.MENU_BOX_PRICES_RECEIVE:
        return Immutable.fromJS(action.prices)

      default:
        return state
    }
  },

  menuBoxPricesTariff: (state = null, action) => {
    switch (action.type) {
      case actionTypes.MENU_BOX_PRICES_RECEIVE:
        return action.tariffId || null

      default:
        return state
    }
  },

  menuBrowseCTAShow: (state = false, action) => {
    switch (action.type) {
      case actionTypes.MENU_BROWSE_CTA_VISIBILITY_CHANGE:
        return action.show
      default:
        return state
    }
  },

  menuPreferencesShow: (state = false, action) => {
    switch (action.type) {
      case actionTypes.MENU_PREFERENCES_VISIBILITY_CHANGE:
        return action.show
      default:
        return state
    }
  },

  menuCollections: (state = Immutable.OrderedMap(), action) => {
    switch (action.type) {
      case actionTypes.MENU_COLLECTIONS_RECEIVE: {
        const collections = Immutable.fromJS(action.collections)
        let newState = Immutable.OrderedMap()
        collections.forEach((collection) => {
          newState = newState.set(collection.get('id'), collection)
        })

        return newState
      }
      default:
        return state
    }
  },
}
