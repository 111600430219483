export const findTag = (allTags, tag) => {
  const foundTag = allTags && allTags.find((tagData) => tagData.slug === tag)

  if (foundTag) {
    const foundTheme = foundTag.themes.find((theme) => theme.name === 'light')

    return {
      ...foundTag,
      themes: undefined,
      theme: foundTheme,
    }
  }

  return null
}
