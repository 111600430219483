import React, { useEffect, useRef } from 'react'

import classnames from 'classnames'
import Helmet from 'react-helmet'
import { forceCheck as forceCheckLazyload } from 'react-lazyload'
import { useDispatch } from 'react-redux'

import redirectActions from 'actions/redirect'
import { ErrorPage } from 'components/ErrorPage'
import { RibbonTrigger } from 'components/RibbonTrigger'
import menu from 'config/menu'
import { client as clientRoutes } from 'config/routes'
import { useBasketImprovements } from 'hooks/useBasketImprovements'
import { useIsRightSizingExperimentEnabled } from 'hooks/useIsRightSizingExperimentEnabled'
import { MainLayout } from 'layouts/MainLayout'
import { MenuOverlay } from 'routes/Menu/MenuOverlay'
import { MenuProps } from 'routes/Menu/MenuProps'
import { menuOverlayClick } from 'routes/Menu/actions/menuOverlayClick'
import { useBasket } from 'routes/Menu/domains/basket'

import { BasketChangesModal } from './components/Basket/BasketChangesModal'
import { BasketContainer } from './components/Basket/BasketContainer'
import { CheckoutErrorModal } from './components/Basket/CheckoutErrorModal'
import { BoxSummaryContainer } from './components/BoxSummary'
import { MenuError } from './components/MenuError'
import { MenuPreferencesSidebar } from './components/MenuPreferences/MenuPreferencesSidebar'
import { DetailRecipeMetaContainer } from './components/RecipeMeta'
import { RecipesInBasketProgress } from './components/RecipesInBasketProgress'
import { RecipeSearchModal } from './components/Search/RecipeSearchModal/RecipeSearchModal'
import { MenuQueryContextProvider } from './context/menuQueryContext'

import css from './Menu.css'

export const Menu: React.FC<MenuProps> = (props) => {
  const dispatch = useDispatch()
  const { addRecipe, date, removeRecipe } = useBasket()
  const isRightSizingExperimentEnabled = useIsRightSizingExperimentEnabled()
  const isBasketImprovementsEnabled = useBasketImprovements()

  const {
    params,
    query,
    basketNumPortionChange,
    disabled,
    menuLoadingBoxPrices,
    menuLoadBoxPrices,
    menuCalculateTimeToUsable,
    fetchData,
    applyPromoCodeAndShowModal,
    hasBasketPromo,
    showOverlay,
    isAuthenticated,
    children,
    isActionBarRedesignEnabled,
    hasMenuError,
    loginVisibilityChange,
    tariffId,
  } = props

  const { orderId } = params
  const prevTariffId = useRef(tariffId)
  const prevIsAuthenticated = useRef(isAuthenticated)
  const shouldRedirectToDeliveryDetails = isAuthenticated && !orderId && !date

  useEffect(() => {
    if (shouldRedirectToDeliveryDetails) {
      dispatch(redirectActions.replace(clientRoutes.deliveryDetails))
    }
  }, [dispatch, shouldRedirectToDeliveryDetails])

  useEffect(() => {
    const loadMenu = async () => {
      const forceDataLoad = Boolean(query.reload)
      // TODO: Add back logic to check what needs to be reloaded

      if (query?.num_portions) {
        basketNumPortionChange(query.num_portions)
      }

      await fetchData({ query, params }, forceDataLoad, undefined, {
        addRecipe,
      })

      const promises = []

      if (!disabled && !menuLoadingBoxPrices) {
        promises.push(menuLoadBoxPrices())
      }

      if (!orderId && !hasBasketPromo) {
        promises.push(applyPromoCodeAndShowModal())
      }

      await Promise.all(promises)
      menuCalculateTimeToUsable()
    }

    loadMenu()

    return () => {
      loginVisibilityChange(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isAdminQuery = !!(query && query['preview[auth_user_id]'])
    if (!disabled && !menuLoadingBoxPrices && prevTariffId.current !== tariffId) {
      menuLoadBoxPrices()
    }

    if (!isAdminQuery && prevIsAuthenticated.current !== isAuthenticated) {
      fetchData({ query, params }, false, undefined, {
        addRecipe,
      })
    }

    forceCheckLazyload()

    // Update refs after effect runs
    prevTariffId.current = tariffId
    prevIsAuthenticated.current = isAuthenticated
  }, [
    tariffId,
    isAuthenticated,
    disabled,
    menuLoadingBoxPrices,
    query,
    params,
    addRecipe,
    fetchData,
    menuLoadBoxPrices,
  ])

  const onOverlayClick = () => {
    dispatch(menuOverlayClick(removeRecipe))
  }

  const overlayShowCSS = showOverlay ? css.blur : null

  if (!isAuthenticated && orderId) {
    return (
      <MainLayout>
        <ErrorPage />
      </MainLayout>
    )
  }

  if (hasMenuError) {
    return (
      <MainLayout>
        <MenuError />
      </MainLayout>
    )
  }

  return shouldRedirectToDeliveryDetails ? null : (
    <MainLayout route={{ withRecipeBar: true }}>
      <MenuQueryContextProvider value={query}>
        <div data-testing="menuContainer">
          <Helmet title={menu.helmet.title} meta={menu.helmet.meta} style={menu.helmet.style} />
          <DetailRecipeMetaContainer />
          <div className={classnames(css.container, overlayShowCSS)}>
            {children}
            {
              // overlay cannot be focused with keyboard, so it makes no sense
              // to add keyboard handling for it
            }
            <MenuOverlay
              className={showOverlay ? css.greyOverlayShow : css.greyOverlay}
              onClick={onOverlayClick}
            />
          </div>
          {isBasketImprovementsEnabled && <CheckoutErrorModal />}
          {isBasketImprovementsEnabled && <BasketChangesModal />}
          {isBasketImprovementsEnabled ? <BasketContainer /> : <BoxSummaryContainer />}
          {!isRightSizingExperimentEnabled && !isBasketImprovementsEnabled && (
            <RecipesInBasketProgress
              isAuthenticated={isAuthenticated}
              isActionBarRedesignEnabled={isActionBarRedesignEnabled}
            />
          )}
        </div>
        <RibbonTrigger name="menu" />
      </MenuQueryContextProvider>
      <RecipeSearchModal />
      <MenuPreferencesSidebar />
    </MainLayout>
  )
}
