import { recipeReferencePrefix } from '@library/menu-service'

// Determines if the user has scrolled past a certain threshold
// and whether the scroll jump was significant enough to trigger animations.
export const calculateScrollState = (threshold, animationThreshold, hasScrolledPast) => {
  const currentOffset = window.pageYOffset

  if (currentOffset < threshold && hasScrolledPast) {
    return { scrolledPastPoint: false, scrollJumped: false }
  }

  if (currentOffset >= threshold && !hasScrolledPast) {
    return {
      scrolledPastPoint: true,
      scrollJumped: currentOffset - threshold > animationThreshold,
    }
  }

  return null
}

// Generates all possible order permutations of an array.
const generatePermutations = (inputArray) => {
  const result = []

  const permute = (array, currentPermutation = []) => {
    if (array.length === 0) {
      result.push(currentPermutation)
    } else {
      for (let i = 0; i < array.length; i++) {
        const remainingItems = array.slice()
        const nextItem = remainingItems.splice(i, 1)
        permute(remainingItems.slice(), currentPermutation.concat(nextItem))
      }
    }
  }

  permute(inputArray)

  return result
}

// Converts a variant group into a list containing the main recipe and its alternative recipes.
const extractRecipeVariants = (variantGroup) => {
  const mainVariant = {
    id: variantGroup.id,
    coreRecipeId: variantGroup.core_recipe_id,
    displayName: variantGroup.attributes?.short_display_name,
  }

  const alternativeVariants = variantGroup.relationships
    .filter((relation) => relation.data.type === 'recipe')
    .map((relation) => ({
      id: relation.data.id,
      coreRecipeId: relation.data.core_recipe_id,
      displayName: relation.data.attributes?.short_display_name,
    }))

  return [mainVariant, ...alternativeVariants]
}

// Creates a lookup table mapping recipes to their alternative variants for easy lookup.
// Example transformation:
// Input: { id: 'A', alternatives: [{ id: 'B' }, { id: 'C' }] }
// Output:
// { 'A': ['B', 'C'], 'B': ['A', 'C'], 'C': ['A', 'B'] }
const generateVariantMappings = (variantGroup) => {
  const variantList = extractRecipeVariants(variantGroup)

  // If there are no alternatives, no need to create mappings.
  if (variantList.length < 2) return null

  const allPermutations = generatePermutations(variantList)

  // Store only one permutation per key to reduce redundancy.
  const uniqueMappings = allPermutations.reduce((acc, cur) => {
    const [firstItem] = cur
    if (!acc[firstItem.coreRecipeId]) {
      acc[firstItem.coreRecipeId] = cur
    }

    return acc
  }, {})

  return Object.values(uniqueMappings)
}

// Processes a list of variant groups for a menu and organizes them into a lookup table.
const processMenuVariantGroups = (menuVariantGroups) => {
  const menuVariantLookup = {}

  menuVariantGroups.forEach((variantGroup) => {
    const permutations = generateVariantMappings(variantGroup)

    if (!permutations || !variantGroup.relationships?.[0]?.type) return

    if (variantGroup.relationships[0].type === 'alternative') {
      permutations.forEach(([parentVariant, ...alternativeVariants]) => {
        if (!menuVariantLookup[parentVariant.coreRecipeId]) {
          menuVariantLookup[parentVariant.coreRecipeId] = {
            displayName: parentVariant.displayName,
            alternatives: alternativeVariants,
          }
        }
      })
    }
  })

  return menuVariantLookup
}

// Extracts variant information from a list of menus and creates a lookup table.
export const mapMenuVariants = (menus) => {
  const menuVariantsById = {}

  menus.forEach((menu) => {
    if (!menu?.relationships?.recipe_options?.data) return

    menuVariantsById[menu.id] = processMenuVariantGroups(menu.relationships.recipe_options.data)
  })

  return menuVariantsById
}

// Updates the selected recipe variants while preserving existing selections.
export const updateSelectedVariants = (originalVariants, payload) => {
  const currentVariants = originalVariants[payload.collectionId]
  let updatedVariants = { ...currentVariants }

  if (currentVariants) {
    updatedVariants = Object.fromEntries(
      Object.entries(currentVariants).filter(([_, value]) => value !== payload.originalRecipeId),
    )
  }

  return {
    ...originalVariants,
    [payload.collectionId]: {
      ...updatedVariants,
      [payload.originalRecipeId]: payload.variantId,

      // Preserve existing reference-based keys (e.g., meal plan references).
      ...Object.fromEntries(
        Object.keys(currentVariants || {})
          .filter((key) => key.startsWith(recipeReferencePrefix))
          .map((key) => [key, currentVariants[key]]),
      ),

      // Include the new reference-based mapping if provided.
      ...(payload.recipeReference && { [payload.recipeReference]: payload.variantId }),
    },
  }
}
