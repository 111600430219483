import React from 'react'

type MenuOverlayProps = {
  className: string
  onClick: () => void
}

export function MenuOverlay({ className, onClick }: MenuOverlayProps) {
  return (
    <div
      aria-hidden="true"
      role="presentation"
      data-testid="menuOverlay"
      className={className}
      onClick={onClick}
    />
  )
}
