import { createSelector } from 'reselect'

import { actionTypes } from 'actions/actionTypes'
import { getRecipes, getMenuRecipeIds } from 'selectors/root'

export const getMenuLoadingErrorMessage = (state) => state.menu.get('menuLoadingErrorMessage')
export const getRecipeCount = (state) => getRecipes(state).size

export const getCurrentMenuRecipes = createSelector(
  [getRecipes, getMenuRecipeIds],
  (allRecipes, currentMenuIds) =>
    currentMenuIds &&
    currentMenuIds
      .map((recipeId) => allRecipes.get(recipeId) || null)
      .filter((recipe) => recipe !== null),
)

export const isMenuLoading = createSelector(
  [
    (state) => state.boxSummaryShow.get('show'),
    (state) => state.menuBrowseCTAShow,
    (state) => state.pending.get(actionTypes.MENU_FETCH_DATA, false),
  ],
  (boxSummaryShow, menuBrowseCTAShow, menuLoading) => {
    const overlayShow = boxSummaryShow || menuBrowseCTAShow

    return menuLoading && !overlayShow
  },
)

export const getHasClosedPropositionTile = (state) =>
  state.menu.get('hasClosedPropositionTile', false)

export const getHasProspectNotSeenWizard = createSelector(
  [
    (state) => state.signup && state.signup.get('isInWizardFunnel'),
    (state) => state.auth && state.auth.get('isAuthenticated'),
  ],
  (isInWizardFunnel, isAuthenticated) => !isInWizardFunnel && !isAuthenticated,
)
