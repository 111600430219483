import { useSelector } from 'react-redux'
import useSWR from 'swr'

import routes from 'config/routes'
import { getAccessToken } from 'selectors/auth'

import { buildSubscriptionQueryUrl } from '../../routes/Account/apis/subscription'
import { getFetcher } from '../../routes/Menu/apis/fetch'
import { getUserId } from '../../selectors/user'
import { OrderState } from './OrderListBanner/FiveRecipesAwarenessOrderListBanner'

type Time = string
type DateTime = string
type UUID = string
type Subscription = {
  status: 'OK'
  data: {
    userId: string
    subscription: {
      origin: string
      deliverySlotStartTime: Time
      interval: number
      deliverySlotEndTime: Time
      status: string
      createdAt: DateTime
      numPortions: 2 | 4
      updatedBy: UUID
      authUserId: UUID
      deliverySlotDay: number
      boxType: 'gourmet' | 'vegetarian'
      updatedAt: DateTime
      userId: string
      numRecipes: 2 | 3 | 4
      versionId: UUID
      intervalUnit: 'weeks' | 'months'
      lastChangeType: string
    }
  }
}

export const useSubscription = () => {
  const accessToken = useSelector(getAccessToken)
  const userId = useSelector(getUserId)
  const url = buildSubscriptionQueryUrl(userId, routes.subscriptionQuery.subscriptions)
  const queryString = null
  const parametersForFetcher =
    accessToken && userId ? [url, queryString, accessToken, userId] : null

  const { data: response } = useSWR<Subscription>(parametersForFetcher, getFetcher, {
    shouldRetryOnError: false,
  })

  return response?.data?.subscription
}

export const use5RecipesAwarenessInlineBanner = (orderState: OrderState) => {
  const subscription = useSubscription()

  return subscription?.numPortions === 2 && orderState === OrderState.MenuOpen
}
